import * as React from "react"
import Zoom from "react-medium-image-zoom"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

interface Extra {
  heading: React.ReactNode
  body: React.ReactNode
  role: React.ReactNode
  detail?: React.ReactNode
}

const extrasList: Extra[] = [
  {
    heading: (
      <Link to="/overviews/dev-notes" style={{ marginBottom: "2px" }}>
        Dev Notes
      </Link>
    ),
    body: (
      <Link to="/overviews/dev-notes" aria-label="Dev Notes overview">
        <StaticImage
          src="../../images/devnotes/DevNotes5.jpg"
          alt="The front page of a Dev Notes instance with a basic header, a simple form, a section with a tag filters and search, and two placeholder example notes that had been saved below. The header has one link called Dev Notes. The form has one submission button and four fields: a basic text field called Topic, a larger textarea field called Solution, an autocompleting select field to add additional Solutions, an additional autocompleting select field to create or add tags. The submission button at the bottom currently displays the error: A Topic is required to create a Note. Only the titles and tags of the two placeholder notes can be seen. One of which is Tyrannosaurus with the tag Cretaceous, and the other is Camarasaurus with the tag Jurassic."
        />
      </Link>
    ),
    role: "UI/UX Designer, Web Developer (2022)",
    detail: (
      <>
        {/* Made with Figma, HTML /CSS, Node.js and React. More details about the
        project are in this
        More info in the
        <Link to="/overviews/dev-notes">Dev Notes Overview</Link> */}
      </>
    ),
  },
  {
    heading: (
      <a
        href="https://wildermythsoundtrack.com/"
        style={{ marginBottom: "2px" }}
      >
        Wildermyth OST Site
      </a>
    ),
    body: (
      <a
        href="https://wildermythsoundtrack.com/"
        aria-label="Wildermyth OST site"
      >
        <StaticImage
          src="../images/extras/WildermythOST.jpg"
          alt="A screenshot of an earthy but brightly colored webpage highlighting the future release of the Wildermyth OST. The page prominetly features the Wildermyth logo at the top with its simple font and W embellished to look like two trees, as well as a screenshot from the game with a diverse group of adventurers gathered around a campfire enjoying some music."
        />
      </a>
    ),
    role: "UI/UX Designer, Web Developer (2022)",
    detail: (
      <>
        {/* Made with Figma, Affinity Designer, HTML /CSS, and React. <br />
        Take a look at the{" "}
        <a href="https://wildermythsoundtrack.com/">Wildermyth OST site</a> */}
      </>
    ),
  },
  {
    heading: (
      <a
        href="https://www.megancarnesmusic.com/"
        style={{ marginBottom: "2px" }}
        target="_blank"
      >
        Megan Carnes Music Portfolio
      </a>
    ),
    body: (
      <a
        href="https://www.megancarnesmusic.com/"
        aria-label="Megan Carnes Music portfolio site"
        target="_blank"
      >
        <StaticImage
          src="../images/extras/MeganCarnesMusic.jpg"
          alt="A screenshot of the musical works page of the Megan Carnes Music portfolio website in a simple green and white color palette. The page features a set of user-controlled filters like Tempo and Mood to find specific types of Megan's music more easily."
        />
      </a>
    ),
    role: "UI/UX Designer, Web Developer (2022)",
    detail: (
      <>
        {/* Made with Figma, Affinity Designer, HTML /CSS, and React. <br />
        Take a look at the{" "}
        <a href="https://www.megancarnesmusic.com/">
          Megan Carnes Music portfolio site
        </a> */}
      </>
    ),
  },
  {
    heading: "Sneaky Snacks",
    body: (
      <StaticImage
        src="../images/extras/SneakySnacks.jpg"
        alt="A screenshot of the game Sneaky Snacks where you play as a mischievous dog trying to get the delicious turkey leg behind the back of your owner. It's got a simple visual style using pixel art and a top-down view of a small room that the dog navigates."
      />
    ),
    role: "Programmer (UI/Game), Composer (2020)",
    detail: (
      <>
        Made for <b>Itch.io Major Jam 2: Love!</b> with Unity. <br />
        <a href="https://team-leaf.itch.io/sneaky-snacks">
          Instructions to play Sneaky Snacks
        </a>
      </>
    ),
  },
  {
    heading: "Non Player Crafter",
    body: (
      <StaticImage
        src="../images/extras/NonPlayerCrafter.jpg"
        alt="A pixel art screenshot of a green hand slamming a hammer into a sword to craft it."
      />
    ),
    role: "Programmer (UI/Game/Audio) (2020)",
    detail: (
      <>
        Made for <b>Global Game Jam 2020</b> with Unity. <br />
        <a href="https://treecki.itch.io/non-player-crafter">
          Instructions to play Non Player Crafter
        </a>
      </>
    ),
  },
  {
    heading: "International Rescue Committee",
    body: (
      <a
        href="https://www.rescue.org/"
        aria-label="Rescue.org Website"
        target="_blank"
      >
        <StaticImage
          src="../images/extras/RescueKR.jpg"
          alt="A screenshot of a webpage of Rescue.org featuring a black-and-white photo of a woman holding their child in the snow."
        />
      </a>
    ),
    role: "Web Developer (2021 - Present)",
    detail: (
      <>
        I worked primarily on the IRC's{" "}
        <a id="rescue-org" href="https://www.rescue.org/" target="_blank">
          rescue.org
        </a>
        {/* My work in this role has included conducting A/B testing for new visual
        components, optimizing the content creation experience for editors, and
        regularly performing accessibility audits — all while keeping multiple
        site translations in mind. */}
      </>
    ),
  },
  {
    heading: "Datastax Academy",
    body: (
      <StaticImage
        src="../images/extras/DSAcademy.jpg"
        alt="A screenshot of an older look of DataStax Academy with a simple hero with a patten of bright blue and orange lines. Below the hero ther are three separate columns of suggestions of Start Learning, Browse By Topics, and Get Certified."
      />
    ),
    role: <>UX Lead (2019 - 2020), Web Developer (2017-2020)</>,
    detail: (
      <>
        {/* My work in this role included many A/B tests for new visual components,
        user research with heatmaps, and redesigning the user workflow of
        downloading their primary product. */}
      </>
    ),
  },
  {
    heading: "Morning Person Games Site",
    body: (
      <StaticImage
        src="../images/extras/MPG1.jpg"
        alt="A screenshot of the morning person games website that features a coffee cup being lit up by a sunrise as a logo, a banner featuring Open World Game: the Open World Game, and a section for recent blog posts with a thumbnail of some heavy armor colored in different styles. The page has the headline: Early morning indie devs with a passion for fun, inclusivity, and great games."
      />
    ),
    role: "UI/UX Designer, Web Developer (2020)",
    detail: (
      <>
        {/* It's in dire need of a update, but here's the{" "}
          <a href="https://morningpersongames.com/">
            Morning Person Games site
          </a>
          . */}
      </>
    ),
  },
  {
    heading: "Metamorphic",
    body: (
      <StaticImage
        src="../images/extras/Metamorphic.jpg"
        alt="A screenshot of the game Metamorphic featuring a cave-like environment with luminescent crystals and a magical glowing circle all in a low-poly style."
      />
    ),
    role: "Game Designer, Sound Designer, Composer (2018)",
    detail: (
      <>
        {/* Made with Unity.{" "}
          <a href="https://wordofmouthgames.itch.io/metamorphic">
            Instructions to play
          </a>
          . */}
      </>
    ),
  },
]

export default extrasList
